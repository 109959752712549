import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-132e918b"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  "class": "share-detail"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.drones, function (item) {
    return _openBlock(), _createBlock($setup["ShareItem"], {
      key: item.droneId,
      drone: item,
      id: item.aircraftSerialNumber
    }, null, 8 /* PROPS */, ["drone", "id"]);
  }), 128 /* KEYED_FRAGMENT */))]);
}