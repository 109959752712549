import "core-js/modules/es.function.name.js";
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-720fc200"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  key: 1,
  "class": "inline"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_el_tooltip = _resolveComponent("el-tooltip");
  var _component_el_checkbox = _resolveComponent("el-checkbox");
  var _component_el_checkbox_group = _resolveComponent("el-checkbox-group");
  return _openBlock(), _createBlock(_component_el_checkbox_group, {
    modelValue: $setup.templateType,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
      return $setup.templateType = $event;
    }),
    "class": "template-type-selector"
  }, {
    "default": _withCtx(function () {
      return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.templateTypeValues, function (item) {
        return _openBlock(), _createBlock(_component_el_checkbox, {
          value: item.id,
          key: item.id,
          disabled: !item.support
        }, {
          "default": _withCtx(function () {
            return [$props.textType === 'tooltip' ? (_openBlock(), _createBlock(_component_el_tooltip, {
              key: 0,
              placement: "top",
              content: item.title
            }, {
              "default": _withCtx(function () {
                return [_createVNode($setup["DeepSvgIcon"], {
                  name: item.name,
                  "class": "icon"
                }, null, 8 /* PROPS */, ["name"])];
              }),
              _: 2 /* DYNAMIC */
            }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["content"])) : _createCommentVNode("v-if", true), $props.textType === 'label' ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode($setup["DeepSvgIcon"], {
              name: item.name,
              "class": "icon"
            }, null, 8 /* PROPS */, ["name"]), _createElementVNode("div", null, _toDisplayString(item.title), 1 /* TEXT */)])) : _createCommentVNode("v-if", true), $props.textType === '' ? (_openBlock(), _createBlock($setup["DeepSvgIcon"], {
              key: 2,
              name: item.name,
              "class": "icon"
            }, null, 8 /* PROPS */, ["name"])) : _createCommentVNode("v-if", true)];
          }),
          _: 2 /* DYNAMIC */
        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value", "disabled"]);
      }), 128 /* KEYED_FRAGMENT */))];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue"]);
}