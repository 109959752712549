import "core-js/modules/es.function.name.js";
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-4a9e79a2"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  "class": "header"
};
var _hoisted_2 = {
  "class": "middle"
};
var _hoisted_3 = {
  "class": "flex-half"
};
var _hoisted_4 = {
  "class": "key"
};
var _hoisted_5 = {
  "class": "flex-half"
};
var _hoisted_6 = {
  "class": "key"
};
var _hoisted_7 = {
  "class": "bottom"
};
var _hoisted_8 = {
  "class": "date"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_el_dropdown_item = _resolveComponent("el-dropdown-item");
  var _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu");
  var _component_el_dropdown = _resolveComponent("el-dropdown");
  var _component_el_tooltip = _resolveComponent("el-tooltip");
  return _openBlock(), _createElementBlock("div", {
    "class": _normalizeClass(["wayline-item", $setup.selected ? 'wayline-item-selected' : '']),
    onClick: $setup.handleSelected
  }, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", {
    "class": _normalizeClass(["name", $setup.selected ? 'name-selected' : ''])
  }, _toDisplayString($setup.name), 3 /* TEXT, CLASS */), $props.show ? (_openBlock(), _createBlock(_component_el_dropdown, {
    key: 0,
    onCommand: $setup.handleCommand
  }, {
    dropdown: _withCtx(function () {
      return [_createVNode(_component_el_dropdown_menu, null, {
        "default": _withCtx(function () {
          return [_createVNode(_component_el_dropdown_item, {
            command: "download"
          }, {
            "default": _withCtx(function () {
              return [_createTextVNode("下载")];
            }),
            _: 1 /* STABLE */
          }), _createVNode(_component_el_dropdown_item, {
            command: "edit"
          }, {
            "default": _withCtx(function () {
              return [_createTextVNode("编辑")];
            }),
            _: 1 /* STABLE */
          }), _createVNode(_component_el_dropdown_item, {
            command: "delete"
          }, {
            "default": _withCtx(function () {
              return [_createTextVNode("删除")];
            }),
            _: 1 /* STABLE */
          })];
        }),
        _: 1 /* STABLE */
      })];
    }),
    "default": _withCtx(function () {
      return [_createVNode($setup["DeepSvgIcon"], {
        name: "svg-arrow-left",
        style: {
          "rotate": "-90deg"
        },
        "class": _normalizeClass(["icon", $setup.selected ? 'icon-selected' : ''])
      }, null, 8 /* PROPS */, ["class"])];
    }),
    _: 1 /* STABLE */
  })) : _createCommentVNode("v-if", true)]), _createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createVNode($setup["DeepSvgIcon"], {
    name: "vehicle",
    "class": "icon"
  }), _createElementVNode("div", _hoisted_4, _toDisplayString($setup.aircraftEnumKey), 1 /* TEXT */)]), _createElementVNode("div", _hoisted_5, [_createVNode($setup["DeepSvgIcon"], {
    name: "camera-icon",
    "class": "icon"
  }), _createElementVNode("div", _hoisted_6, _toDisplayString($setup.payloadEnumKey), 1 /* TEXT */)])]), _createElementVNode("div", _hoisted_7, [_createElementVNode("div", _hoisted_8, "更新时间" + _toDisplayString($setup.updateTime), 1 /* TEXT */), _createVNode(_component_el_tooltip, {
    placement: "top",
    content: $setup.templateTypeDesc
  }, {
    "default": _withCtx(function () {
      var _a;
      return [_createVNode($setup["DeepSvgIcon"], {
        name: 'route-' + ((_a = $setup.props.data) === null || _a === void 0 ? void 0 : _a.templateType),
        "class": "icon"
      }, null, 8 /* PROPS */, ["name"])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["content"])])], 2 /* CLASS */);
}