import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-d009746c"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  "class": "item"
};
var _hoisted_2 = {
  key: 0,
  "class": "player"
};
var _hoisted_3 = {
  key: 1,
  "class": "placeholder"
};
var _hoisted_4 = {
  "class": "screen"
};
var _hoisted_5 = {
  "class": "drone-name"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode($setup["DeepAspectView"], {
    aspect: "16: 9"
  }, {
    "default": _withCtx(function () {
      return [$setup.JlinkMqtt2.getAircraftOnline($setup.drone) ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createVNode($setup["DeepRtcPlayer"], {
        drone: $setup.drone,
        "auto-play": false
      }, null, 8 /* PROPS */, ["drone"])])) : (_openBlock(), _createElementBlock("div", _hoisted_3, " 飞机暂未开启播放 "))];
    }),
    _: 1 /* STABLE */
  }), _createElementVNode("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, _toDisplayString($setup.drone.droneName), 1 /* TEXT */), $setup.JlinkMqtt2.getAircraftOnline($setup.drone) ? (_openBlock(), _createBlock($setup["DeepSvgIcon"], {
    key: 0,
    "class": "icon",
    name: "fullscreen-exit",
    onClick: $setup.handleDockScreen
  })) : _createCommentVNode("v-if", true)])]);
}